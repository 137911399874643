import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Posts from './index';
import Search from './search';

export const fragment = graphql`
  fragment PostsCollection on Posts {
    slug
    rawBody
    name
    frontmatter {
      date
      update
      draft
      path
      title
      description
      categories
      keywords
      garden
      image
      author {
        id
        tag
        info {
          description
          name
        }
      }
    }
    id
    internal {
      type
    }
    parent {
      ... on Mdx {
        id
        excerpt
        body
      }
    }
  }
`;

export default function withData(Component) {
  const WithDataComponent = props => {
    return (
      <StaticQuery
        query={graphql`
          query {
            allPosts {
              nodes {
                ...PostsCollection
              }
            }
          }
        `}
        render={data => <Component data={data.allPosts.nodes} {...props} />}
      />
    );
  };
  return WithDataComponent;
}

export const PostsWithData = withData(props => <Posts {...props} />);
export const PostsWithDataSearch = withData(({ data, ...props }) => (
  <Search posts={data} {...props} />
));
